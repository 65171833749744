import React, { useContext, useMemo } from "react";
import { CheckCircle, ChevronDown, ChevronRight } from "lucide-react";
import { BookOpen } from "lucide-react";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/collapsible";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
  SidebarRail,
} from "../components/ui/sidebar";
import { Progress } from "./ui/progress";
// import { GET_COURSE_GOAL_COMPLETION_STATS } from "src/pages/course/queries";
import { Link, useParams } from "react-router-dom";
import { FirebaseContext } from "src/firebase";
import { useQuery } from "@apollo/client";
import { FaLock } from "react-icons/fa";

export function AppSidebar({
  course_modules,
  participantId,
  courseId,
  ...props
}) {
  const isContentBlockCompleted = (contentBlock) => {
    return contentBlock.course_content_block_submissions?.length > 0;
  };

  const params = useParams();
  const { currentUser, token } = useContext(FirebaseContext);

  const calculateProgress = (module) => {
    let totalCount = module.course_content_blocks.length;
    let completedCount = 0;

    for (let i = 0; i < module.course_content_blocks.length; i++)
      if (isContentBlockCompleted(module.course_content_blocks[i]))
        completedCount++;

    for (let i = 0; i < module.course_sub_modules.length; i++) {
      totalCount += module.course_sub_modules[i].course_content_blocks.length;

      for (
        let j = 0;
        j < module.course_sub_modules[i].course_content_blocks.length;
        j++
      )
        if (
          isContentBlockCompleted(
            module.course_sub_modules[i].course_content_blocks[j]
          )
        )
          completedCount++;
    }

    return (completedCount / totalCount) * 100;
  };

  // const { loading: progressloading, data: progressData } = useQuery(
  //   GET_COURSE_GOAL_COMPLETION_STATS,
  //   {
  //     variables: {
  //       course_id: params.course_id,
  //       user_id: currentUser.id,
  //     },
  //   }
  // );

  // const course_progess = useMemo(() => {
  //   if (progressData) {
  //     const count_stats = progressData.view_course_content_block_stats[0];
  //     return count_stats?.total_modules > 0
  //       ? (
  //           (count_stats.completed_modules / count_stats.total_modules) *
  //           100
  //         ).toFixed(0)
  //       : 0;
  //   }
  //   return 0;
  // }, [progressData]);

  const renderContentBlock = (
    contentBlock,
    parentId,
    blockIndex,
    isSubModule = false
  ) => {
    if (!contentBlock) return null;

    let linkTo;
    if (isSubModule) {
      linkTo = `/course/${courseId}/${participantId}/${parentId}/${contentBlock.id}`;
    } else {
      linkTo = `/course/${courseId}/${participantId}/${parentId}/${contentBlock.id}`;
    }

    const isCompleted = isContentBlockCompleted(contentBlock);

    return (
      <Link to={linkTo} key={contentBlock.id}>
        <SidebarMenuSubItem key={blockIndex}>
          <SidebarMenuButton
            className={`py-1 text-gray-600 min-h-fit hover:bg-[#e1e7fd] ${
              isCompleted
                ? "bg-green-100 text-green-700 dark:text-green-300"
                : ""
            } ${
              params.content_block_id === contentBlock.id ? "bg-[#e1e7fd]" : ""
            }`}
          >
            {isCompleted && <CheckCircle className="h-4 w-4" />}
            <span>{contentBlock.name}</span>
          </SidebarMenuButton>
        </SidebarMenuSubItem>
      </Link>
    );
  };

  const renderSubModule = (subModule, moduleId, subModuleIndex) => {
    if (!subModule) return null;

    const sortedContentBlocks = [
      ...(subModule.course_content_blocks || []),
    ].sort((a, b) => (a.order || 0) - (b.order || 0));

    console.log(subModule);

    return (
      <SidebarMenuItem key={subModuleIndex}>
        <Collapsible
          className="group/collapsible [&[data-state=open]>button>svg:last-child]:rotate-90"
          defaultOpen={
            sortedContentBlocks.length > 0 &&
            sortedContentBlocks
              .reduce((acc, block) => [...acc, block.id], [])
              .includes(params.content_block_id)
          }
        >
          <CollapsibleTrigger asChild>
            <SidebarMenuButton className="flex items-center hover:bg-[#e1e7fd] py-2 min-h-fit">
              {subModule.name}
              <ChevronRight className="ml-auto transition-transform" />
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <SidebarMenuSub className="space-y-[0.5]">
              {sortedContentBlocks.map((block, blockIndex) => {
                return renderContentBlock(block, moduleId, blockIndex);
              })}
            </SidebarMenuSub>
          </CollapsibleContent>
        </Collapsible>
      </SidebarMenuItem>
    );
  };

  const renderModule = (module, moduleIndex) => {
    if (!module) return null;
    const isLocked = module.locked || false;

    const sortedContentBlocks = [...(module.course_content_blocks || [])].sort(
      (a, b) => (a.order || 0) - (b.order || 0)
    );
    const sortedSubModules = [...(module.course_sub_modules || [])].sort(
      (a, b) => (a.order || 0) - (b.order || 0)
    );
    const progress = calculateProgress(module);

    if (isLocked) {
      return (
        <SidebarMenuItem key={moduleIndex}>
          <div className="text-gray-500 font-semibold flex items-center px-2 py-2 justify-between min-h-fit">
            <h1 className="uppercase">{module.name}</h1>
            <div className="flex items-center">
              <span className="text-sm font-normal text-gray-400">
                <FaLock />
              </span>
            </div>
          </div>
        </SidebarMenuItem>
      );
    }

    return (
      <SidebarMenuItem key={moduleIndex}>
        <Collapsible
          className="group/collapsible [&[data-state=open]>button>svg:last-child]:rotate-90"
          defaultOpen={params.course_module_id === module.id || false}
        >
          <CollapsibleTrigger asChild>
            <SidebarMenuButton className="text-[#4e46dc] font-semibold flex items-center px-2 py-2 justify-between min-h-fit">
              <h1 className="uppercase">{module.name}</h1>
              <div className="flex items-center">
                <span className="text-gray-400 font-normal text-xs">
                  {progress.toFixed(0)}%
                </span>
                <ChevronDown className="ml-auto transition-transform" />
              </div>
            </SidebarMenuButton>
          </CollapsibleTrigger>
          <Progress value={progress} className="h-1 w-full my-2" />
          <CollapsibleContent>
            <SidebarMenuSub className="space-y-1">
              {sortedContentBlocks.map((subModule, subModuleIndex) =>
                renderContentBlock(subModule, module.id, subModuleIndex)
              )}
              {sortedSubModules.map((subModule) =>
                renderSubModule(subModule, module.id)
              )}
            </SidebarMenuSub>
          </CollapsibleContent>
        </Collapsible>
      </SidebarMenuItem>
    );
  };

  return (
    <Sidebar {...props}>
      <SidebarHeader className="bg-[#4e46dc]">
        <div className="min-h-10 flex gap-2 items-center">
          <BookOpen className="text-white w-6 h-6" />
          <h1 className="text-white font-bold ">Course Content</h1>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <div className="flex items-center justify-between px-4 py-3 bg-indigo-500 text-white">
          <span className="text-sm font-medium">Course Progress</span>
          <div className="flex items-center gap-1">
            {/* <Progress
              value={course_progess}
              className="w-24 bg-indigo-300"
              indicatorClassName="bg-white"
            />
            <span className="text-sm font-bold">{course_progess}%</span> */}
          </div>
        </div>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu className="space-y-2">
              {course_modules &&
                course_modules.map((module, moduleIndex) => {
                  return renderModule(module, moduleIndex);
                })}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  );
}
